import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { Link } from 'gatsby';
// import Img from "gatsby-image";
import { GatsbyImage as Img } from '@wardpeet/gatsby-image-nextgen/compat';
import { graphql, StaticQuery  } from "gatsby"

const HoverText = styled.a`
	color: #FFF;
	:hover {
		color: yellow;
		cursor: pointer;
	}
`


class Header11 extends Component {

    
    componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }
    
    render() {
        
        return (
            <>
            <StaticQuery
                query={graphql`
                      query logoQuery {
                          logo:file(relativePath: { eq: "duynhat/logo/DN_logo.png" }){
                            childImageSharp {
                                fluid(maxHeight: 800){
                                    aspectRatio
                                    ...GatsbyImageSharpFluid_tracedSVG
                              }
                            }
                          }
                        }
                      `
                }
            render = {data => (
            <header class="site-header mo-left header header-curve ext-header dark ">
                <div class="middle-bar bg-dark">
                    <div class="container-fluid">
                        <div class="middle-area">
                        <Link to="/" aria-label="Home">
                            <div class="logo-header" style={{ maxHeight: 800}}>
                                
                                    {/* <img src={require("../images/duynhat/logo/DN_logo.png")} alt=""/> */}
                                    <Img fluid={data.logo.childImageSharp.fluid} alt=""/>
                              
                            </div>
                            </Link>
                            <div class="service-list">
                                <ul>
                                    <li>
                                        <i class="la la-skype"></i>
                                        <h4 class="title">baobiduynhat</h4>
                                        <span style={{color:"#ebedf2"}}>Skype</span>
                                    </li>
                                    <li>
                                        <i class="la la-phone"></i>
                                        <h4 class="title">+84 2993 612 772</h4>
                                        <span  style={{color:"#ebedf2"}}>Call Us</span>
                                    </li>
                                    <li>
                                        <i class="la la-map"></i>
                                        <h4 class="title">Lot O, An Nghiep Industrial Park</h4>
                                        <span  style={{color:"#ebedf2"}}>Soc Trang, Vietnam</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>	
                
                <Sticky innerZ={999} enabled={true}>
                    <div class="sticky-header main-bar-wraper navbar-expand-lg">
                        <div class="main-bar clearfix ">
                            <div class="container-fluid clearfix">
                                
                                <div class="logo-header mostion">
                                    <Link to="/"><img src={require("../images/duynhat/logo/DN_logo.png")} alt="Duy Nhat Pack (DN Pack) Logo" aria-label="Home"/></Link>
                                </div>
                                
                                <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div class="extra-nav">
                                    <div class="extra-cell">
                                        <ul class="list-inline">
                                            <li><a href={"https://www.facebook.com/DuyNhatBaoBi"} class="site-button-link facebook hover" aria-label="Facebook"><i class="fa fa-facebook"></i></a></li>
                                            <li><a href={"skype:baobiduynhat?chat"} class="site-button-link facebook hover"><i class="fa fa-skype" aria-label="Skype"></i></a></li>
                                            <li><a href={"mailto:sales@baobiduynhat.com.vn"} class="site-button-link linkedin hover" aria-label="Email"><i class="fa fa-envelope"></i></a></li>
                                            <li>
                                                <HoverText href={"https://baobiduynhat.com.vn/"} class="site-button-link facebook"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFsSURBVHjaYvzPgAD/UNlYEUAAmuTYAAAQhAEYqF/zFbe50RZ1cMmS9TLi0pJLRjZohAMTGFUN9HdnHgEE1sDw//+Tp0ClINW/f4NI9d////3+f+b3/1+////+9f/XL6A4o6ws0AaAAGIBm/0fRTVQ2v3Pf97f/4/9Aqv+DdHA8Ps3UANAALEAMSNQNdDGP3+ALvnf8vv/t9//9X/////7f+uv/4K//iciNABNBwggsJP+/IW4kuH3n//1v/8v+wVSDURmv/57//7/CeokoKFA0wECiAnkpL9/wH4CO+DNr/+VQA1A9PN/w6//j36CVIMRxEkAAQR20m+QpSBXgU0CuSTj9/93v/8v//V/xW+48UBD/zAwAAQQSAMzOMiABoBUswCd8ev/M7A669//OX7///Lr/x+gBlCoAJ0DEEAgDUy//zBISoKNAfoepJNRFmQkyJecfxj4/kDCEIiAigECiPErakTiiWMIAAgwAB4ZUlqMMhQQAAAAAElFTkSuQmCC" title="Tiếng Việt" alt="Tiếng Việt" width="24" height="17" style={{
                                                    borderRadius: 4,
                                                    borderWidth: 2,
                                                    borderColor: '#fff'}}/> 
                                                    Tiếng Việt
                                                </HoverText>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div class="dlab-quik-search ">
                                    <form action="#">
                                        <input name="search" value="" type="text" class="form-control" placeholder="Type to search"/>
                                        <span id="quik-search-remove"><i class="ti-close"></i></span>
                                    </form>
                                </div>
                                
                                <div class="header-nav navbar-collapse collapse justify-content-start" id="navbarNavDropdown">
                                    <div class="logo-header d-md-block d-lg-none">
                                        <Link to="/"><img src={require("../images/duynhat/logo/DN_logo.png")} alt=""/></Link>
                                    </div>
                                    <ul class="nav navbar-nav">	
                                        <li class="has-mega-menu homedemo"> <Link to="/">Home</Link>
                                        </li>
                                        <li class="has-mega-menu"> <Link to={'/about'}>About Us</Link>
										</li>
                                        <li class="has-mega-menu"> <Link to={'/facility'}>Facility</Link>
                                        </li>
                                        <li class="has-mega-menu"> <Link to={'/products'}>Products<i class="fa fa-chevron-down"></i></Link>
                                        <ul class="mega-menu">
												<li>
													<Link to={'/products'}>Products</Link>
													<ul>
														<li><Link to={'/flexible'}>Flexible Packaging</Link></li>
														<li><Link to={'/paper'}>Paper Packaging</Link></li>
														<li><Link to={'/labels'}>Printed Labels and Stickers</Link></li>
													</ul>
												</li>
											</ul>
                                        </li>
                                   
										<li class="has-mega-menu"> 
											<Link to={'/blog'}>Blog</Link>
										</li>
									
                                        <li>
                                            <Link to="/contact">Contact Us</Link>
                                        </li>
                                    </ul>	
                                    <div class="dlab-social-icon">
                                        <ul>
                                          <li><a href={"https://www.facebook.com/DuyNhatBaoBi"} class="site-button sharp-sm fa fa-facebook" aria-label="Facebook"><i class="fa fa-facebook"></i></a></li>
                                            <li><a class="site-button sharp-sm fa fa-skype" href={"skype:baobiduynhat?chat"} aria-label="Skype"></a></li>
                                            <li><a class="site-button sharp-sm fa fa-envelope" href={"sales@baobiduynhat.com.vn"}  aria-label="Mail"></a></li>
                                            <li>
                                                <a href={"https://baobiduynhat.com.vn/"} class="site-button sharp-sm" aria-label="Vietnamese"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFsSURBVHjaYvzPgAD/UNlYEUAAmuTYAAAQhAEYqF/zFbe50RZ1cMmS9TLi0pJLRjZohAMTGFUN9HdnHgEE1sDw//+Tp0ClINW/f4NI9d////3+f+b3/1+////+9f/XL6A4o6ws0AaAAGIBm/0fRTVQ2v3Pf97f/4/9Aqv+DdHA8Ps3UANAALEAMSNQNdDGP3+ALvnf8vv/t9//9X/////7f+uv/4K//iciNABNBwggsJP+/IW4kuH3n//1v/8v+wVSDURmv/57//7/CeokoKFA0wECiAnkpL9/wH4CO+DNr/+VQA1A9PN/w6//j36CVIMRxEkAAQR20m+QpSBXgU0CuSTj9/93v/8v//V/xW+48UBD/zAwAAQQSAMzOMiABoBUswCd8ev/M7A669//OX7///Lr/x+gBlCoAJ0DEEAgDUy//zBISoKNAfoepJNRFmQkyJecfxj4/kDCEIiAigECiPErakTiiWMIAAgwAB4ZUlqMMhQQAAAAAElFTkSuQmCC" title="Tiếng Việt" alt="Tiếng Việt" width="16" height="11"/> 
                                                </a>
                                            </li>
                                        </ul>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </Sticky>
            </header>
            )}       
        />
        </>
        )
    }
}
export default Header11;


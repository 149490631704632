import React, { Component } from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';

const bg = require('../images/background/map-bg1.png')
// style="background-image:url(images/background/bg3.png); background-size: cover;"

class Footer4 extends Component {


    render() {
        return (
            <>
            <footer class="site-footer">
                <div class="footer-top" style={{ backgroundImage: "url(" + bg + ")" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                                <div class="widget widget_services border-0">
                                    <h3 class="footer-title text-white" style={{fontSize:28}}>Company</h3>
                                    <ul>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/propducts">Products</Link></li>
                                        {/* <li><Link to="/privacy-policy">Privacy Policy</Link></li> */}
                                        <li><Link to="/facility">Facility </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                                <div class="widget widget_services border-0">
                                    <h3 class="footer-title text-white" style={{fontSize:28}}>Useful Link</h3>
                                    <ul>
                                        <li><Link to="/flexible">Flexible Packaging</Link></li>
                                        <li><Link to="/paper">Paper Packaging</Link></li>
                                        <li><Link to="/labels">Labels and Stickers</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        {/* <li><Link to="/portfolio-details">Portfolio</Link></li> */}
                                        {/* <li><Link to="/team-1">Team</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                                <div class="widget widget_getintuch">
                                    <h3 class="footer-title text-white" style={{fontSize:28}}>Contact us</h3>
                                    <ul>
                                        <li><i class="ti-location-pin"></i><strong>address</strong> Lot O, An Nghiep Industrial Park, An Hiep, Chau Thanh, Soc Trang Province, Vietnam </li>
                                        <li><i class="ti-mobile"></i><strong>Tel</strong>+84 2993 612 772<br></br>+84 2993 616 819</li>
                                        <li><i class="ti-printer"></i><strong>Fax</strong>+84 2993 825 964 </li>
                                        <li><i class="ti-email"></i><strong>Email</strong>sales@baobiduynhat.com.vn</li>
                                        <li><i class="ti-skype"></i><strong>Skype</strong>baobiduynhat</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
                                <div class="widget">
                                    {/* <h3 class="footer-title text-white">Subscribe To Our Newsletter</h3>
                                    <div class="subscribe-form m-b20">
                                        <form class="dzSubscribe" action="script/mailchamp.php" method="post">
                                            <div class="dzSubscribeMsg"></div>
                                            <div class="input-group">
                                                <input name="dzEmail" required="required"  class="form-control" placeholder="Your Email Id" type="email"/>
                                                <span class="input-group-btn">
                                                    <button name="submit" value="Submit" type="submit" class="site-button radius-xl btnhover16">Subscribe</button>
                                                </span> 
                                            </div>
                                        </form>
                                    </div> */}
                                    <ul class="list-inline m-a0">
                                        <li><a href={"https://www.facebook.com/DuyNhatBaoBi"}  class="site-button facebook circle " aria-label="Facebook"><i class="fa fa-facebook"></i></a></li>
                                        <li><Link to="#" class="site-button linkedin circle " aria-label="linkedin"><i class="fa fa-linkedin"></i></Link></li>
                                        {/* <li><Link to="#" class="site-button twitter circle "><i class="fa fa-twitter"></i></Link></li> */}
                                        <li><a href={"skype:baobiduynhat?chat"} class="site-button skype circle " aria-label="Skype"><i class="fa fa-skype"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 text-left "> <span>Copyright © 2020 Duy Nhat Pack </span> </div>
                            <div class="col-md-6 col-sm-6 text-right "> 
                                <div class="widget-link "> 
                                    <ul>
                                        {/* <li><Link to="/about"> About</Link></li>  */}
                                        {/* <li><Link to="/help-desk"> Help Desk</Link></li>  */}
                                        {/* <li><Link to="/privacy-policy"> Privacy Policy</Link></li>  */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>                
            </>
        )
    }
}
export default Footer4;